







































import { Vue, Component } from "vue-property-decorator";
import moment from "moment";

import DateDialog from "@/components/dialogs/DateDialog.vue";
import DocumentsDialog from "@/components/dialogs/DocumentsDialog.vue";

@Component({
    components: {
        "date-dialog": DateDialog,
        "documents-dialog": DocumentsDialog,
    },
})
export default class Main extends Vue {
    private isDateDialogVisible: boolean = false;
    private isDocDialogVisible: boolean = false;

    private data(): object {
        const date = moment().format("YYYY-MM-DD");

        return {
            dateRange: [date, date],
            sales: [],
            chosenPolicy: {},
        };
    }

    private closeDateDialog() {
        this.isDateDialogVisible = false;
    }

    private openDateDialog() {
        this.isDateDialogVisible = true;
    }

    private closeDocDialog() {
        this.isDocDialogVisible = false;
        this.$data.chosenPolicy = {};
    }

    private openDocDialog(policy: any) {
        this.isDocDialogVisible = true;
        this.$data.chosenPolicy = policy;
    }

    private async setDateRange(dateRange: any[]) {
        this.$data.dateRange = dateRange;

        this.closeDateDialog();

        await this.getSales(this.$data.dateRange);
    }

    private async getSales(dateRange: any[]) {
        const [startDate, endDate] = dateRange;

        try {
            const res = await this.$Api.Main.GetSales(startDate, endDate);

            this.$data.sales = res.sales.map((s) => ({ ...s, date: moment(s.date).format("YYYY-MM-DD HH:mm:ss") }));
        } catch (error) {
            alert(error.message || error);
        }

        this.closeDateDialog();
    }
}
