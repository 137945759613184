var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("date-dialog", {
        attrs: {
          isDialogVisible: _vm.isDateDialogVisible,
          dateRange: _vm.dateRange,
        },
        on: {
          dialogAction: _vm.setDateRange,
          closeDialog: _vm.closeDateDialog,
        },
      }),
      _vm.chosenPolicy
        ? _c("documents-dialog", {
            attrs: {
              isDialogVisible: _vm.isDocDialogVisible,
              policy: _vm.chosenPolicy,
            },
            on: { closeDialog: _vm.closeDocDialog },
          })
        : _vm._e(),
      _c(
        "el-container",
        { attrs: { direction: "vertical" } },
        [
          _c(
            "div",
            { staticClass: "menu-buttons" },
            [
              _c("el-button", { on: { click: _vm.openDateDialog } }, [
                _vm._v(
                  "Выбрать дату (" +
                    _vm._s(this.$data.dateRange[0]) +
                    " - " +
                    _vm._s(this.$data.dateRange[1]) +
                    ")"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.sales } },
            [
              _c("el-table-column", {
                attrs: { prop: "email", label: "Email" },
              }),
              _c("el-table-column", {
                attrs: { prop: "car", label: "ID автомобиля" },
              }),
              _c("el-table-column", {
                attrs: { prop: "company", label: "Страховая компания" },
              }),
              _c("el-table-column", {
                attrs: { prop: "date", label: "Дата покупки" },
              }),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID договора" },
              }),
              _c("el-table-column", {
                attrs: { prop: "price", label: "Стоимость" },
              }),
              _c("el-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "column-buttons" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDocDialog(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Печать")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }